<template>
    <div class="scan">

        <ScannerCamera />

        <v-btn fab top left color="primary" small class="qrcode-button ms-5" @click="showScanAndPayDialog = true">
            <v-icon>mdi-qrcode</v-icon>
        </v-btn>

        <div class="d-flex align-center scan-basket">
            <div class="full-width py-1 pr-8 pl-5 mr-n7 d-flex align-end rounded-bl-xl rounded-tl-xl white primary--text" @click="$router.push({name: 'Basket'})">
                <v-badge class="mr-4 mt-2" color="primary" :content="nbrProductsInBasket.toString()" overlap>
                    <v-icon class="mr-1 pr-2" color="primary">mdi-cart-outline</v-icon>
                </v-badge>
                <span class="font-weight-bold text-body-1">{{formatPrice(basketTotalAmount)}}</span>
            </div>
        </div>
        <div class="target-camera" @click="simulateScan()">
            <v-img max-width="300" max-height="300" src="@/assets/img/cam-overlay.png" v-if="isNull(complexeId)" />
            <v-img max-width="600" max-height="600" src="@/assets/img/cam-overlay-barcode.png" contain v-else />
            <div class="scanner-explanation" :style="{backgroundColor: $vuetify.theme.themes.light.primary+ '30'}">
                <p v-if="!error" :style="{backgroundColor: $vuetify.theme.themes.light.primary + '30'}" class="ma-0 px-3 py-5 white--text font-weight-bold text-center font-weight-medium">
                    {{$t("scanProductExplain")}}
                </p>
                <p v-else :style="{backgroundColor: $vuetify.theme.themes.light.error + '80'}" class="ma-0 px-3 py-5 white--text font-weight-bold text-center font-weight-medium">
                    {{$t("scanProductErrorExplain")}}
                </p>
            </div>
        </div>

        <div class="py-5 d-flex flex-column align-center justify-center align-center scan-product-footer">
            <div v-if="scannedProduct" class="px-4 scanned-product-wrapper full-width">
                <ProductBloc :product="scannedProduct" :qty="scannedProductQty" showMore/>
            </div>
            <v-btn class="my-4" fab elevation="0" dark :color="`${$vuetify.theme.themes.light.primary}30`" width="56" height="56"> <v-img width="20" height="20" src="@/assets/img/icon-scan.png" contain></v-img> </v-btn>
            <v-btn class="rounded-pill py-5" elevation="0" dark color="primary" width="90%" small @click="handleFinishCheck()"> {{$t("scanProductFinishOrder")}} </v-btn>
        </div>

        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>

        <v-dialog v-model="showScanAndPayDialog" width="400" content-class="rounded-xl" >
            <v-card>
                <v-card-title>
                    <span class="primary--text text-h5">{{$t('scanAndPayModalTitle')}}</span>
                </v-card-title>

                <v-card-text>
                    <v-img :src="require('@/assets/img/scan-pay-qrcode.png')"></v-img>
                </v-card-text>

                <v-divider class="primary mx-8"></v-divider>

                <v-card-actions class="justify-center">
                    <v-btn small color="primary" class="mb-2 modal-action" @click="showScanAndPayDialog = false">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
    import ScannerCamera from "@/components/subcomponents/ScannerCamera.vue";
    import ProductBloc from "@/components/ProductBloc.vue";
    let Barcode = require("@/assets/js/Barcode");
    import {mapActions, mapState} from 'vuex'
    var CatalogService = require('@/services/CatalogService.js')

    export default {
        name: 'ScanProduct',
        components: {
            ScannerCamera,
            ProductBloc
        },
        data() {
            return {
                basket: null,
                scannedProduct: null,
                cameras: [],
                cameraToUse: null,
                error: false,
                showScanAndPayDialog: false
            }
        },
        computed: {
            ...mapState('basket', ['complexeId', 'products']),
            scannedProductQty(){
                if(this.scannedProduct){
                    return this.qtyByProductIdInBasket(this.scannedProduct.id)
                }
                return 0
            }
        },
        created() {
            this.changeCurrentPage(this.$route.meta.page)
            // this.getCameras()
        },
        mounted () {
            Barcode.start(this.onDecode);
        },
        methods: {
            ...mapActions('basket', ['addProductInBasket']),
            ...mapActions('header', [ 'changeCurrentPage' ]),

            // async getCameras() {
            //     this.cameras = await Barcode.getCameras()
            // },
            simulateScan(){
                let code = {
                    codeResult: {
                        code: "6387733083543"
                    }
                }
                this.onDecode(code)
            },
            onDecode(decodedString) {
                this.$refs.beep.play();
                CatalogService.getProductByEan(decodedString.codeResult.code).then(({ status, data }) => {
                    if (status === 200 && this.isNotNull(data)) {
                        this.addProductInBasket({
                            product: data,
                            qty: 1
                        })
                        this.scannedProduct = data
                        // setTimeout(() => {
                        //     this.scannedProduct = null
                        // }, 10000);
                    } else {
                        this.error = true
                        setTimeout(() => {
                            this.error = false
                        }, 5000);
                    }

                })
            },
            handleFinishCheck(){
                this.$router.push( {name: "Basket"} )
            }

        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            Barcode.reset()
            next();
        },
        watch: {
            cameras() {
                Barcode.reset()
                this.cameraToUse = this.cameras[0]
            },
            cameraToUse(camera) {
                Barcode.reset()
                Barcode.start(camera.device, this.onDecode);
            }
        }

    };
</script>
<style scoped>
    .qrcode-button{
        position: absolute;
        top: 128px;
        z-index: 200;
    }
    .scan-basket{
        position: absolute;
        right: 0;
        top: 128px;
        max-width: 150px;
        width: 90%;
        z-index: 200;
    }
    .scan {
        height: 100%;
        height: calc(var(--vh, 1vh) * 100);
        background: black;
        position: relative;
    }

    .target-camera {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .scanner-explanation {
        position: absolute;
        width: 100%;
    }

    .scan-product-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    .modal-action{
        border-radius: 8px;
    }
</style>
